import APPCONFIG from '../constants/Config';
import {
  TOGGLE_BOXED_LAYOUT,
 // USER_DATA,
  LOGIN_USER_DATA
} from '../constants/ActionTypes';


const initialSettings = APPCONFIG.settings;

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case TOGGLE_BOXED_LAYOUT:
      return {
        ...state,
        layoutBoxed: action.isLayoutBoxed
      };

    case LOGIN_USER_DATA:
      return {
        ...state,
        user: action.user
      }
    default:
      return state;
  }
};

export default settings;
