import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { Route, Switch, Redirect } from 'react-router';
import Loadable from 'react-loadable';

function LoadingComponent() {
  return <div></div>;
}

let ChatView = Loadable({
  loader: () => import('./routes/app/views/components/chatview/ChatView'),
  loading: LoadingComponent
});

let Unauthorized = Loadable({
  loader: () => import('../src/routes/app/views/components/error/401'),
  loading: LoadingComponent
});

let Record = Loadable({
  loader: () => import('../src/routes/app/customcomponents/chat/audio/Audio'),
  loading: LoadingComponent
});


let UserRegister = Loadable({
  loader: () => import('../src/routes/app/views/components/userregistration/UserRegister'),
  loading: LoadingComponent
});

let AudioRecoder = Loadable({
  loader: () => import('../src/routes/app/views/components/error/AudioRecoder'),
  loading: LoadingComponent
});

let NewsFeedDetails = Loadable({
  loader: () => import('./components/NewsFeedDetails/NewsFeedDetails'),
  loading: LoadingComponent
});

let NewsFeed = Loadable({
  loader: () => import('./components/NewsFeed/NewsFeed'),
  loading: LoadingComponent
});

let Advertisement = Loadable({
  loader: () => import('./components/Advertisement/Advertisement'),
  loading: LoadingComponent
});

let ScoreDetails = Loadable({
  loader: () => import('./components/ScoreDetails/ScoreDetails'),
  loading: LoadingComponent
});

let EventDetails = Loadable({
  loader: () => import('./components/EventDetails/EventDetails'),
  loading: LoadingComponent
});

let NotFound = Loadable({
  loader: () => import('./components/404/404'),
  loading: LoadingComponent
});

let Header = Loadable({
  loader: () => import('./components/Header/Header'),
  loading: LoadingComponent
});

let Footer = Loadable({
  loader: () => import('./components/Footer/Footer'),
  loading: LoadingComponent
});

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <header>
          <Header />
        </header>
        <div className="App-intro">
          <Switch>
            <Route exact path="/" component={NewsFeedDetails} />
            <Route path="/newsfeed" component={NewsFeedDetails} />
            <Route path="/event" component={EventDetails} />
            <Route path="/advertisement" component={Advertisement} />
            <Route path="/score" component={ScoreDetails} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/" />
          </Switch>
        </div>
        <footer className="p-0">
          <Footer />
        </footer>
        {/* <div id="loader-overlay">
          <div className="loader-gif"></div>
        </div>
        <Route exact path={`/`} component={ChatView} />
        <Route path={`/chatview`} component={ChatView} />
        <Route path={`/record`} component={Record} />
        <Route path={`/401`} component={Unauthorized} />
        <Route path={`/userregister`} component={UserRegister} />
        <Route path={`/audiorecoder`} component={AudioRecoder} />
       */}
      </div>
    </div>
  );
}

export default App;
